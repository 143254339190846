import React from 'react'
import css from './index.module.css'
import { ReactComponent as LogoIcon } from '../assets/logoViva.svg';

const Logo = () => {
  return (
    <div className={css.component_logo}>
        <LogoIcon />
      {/*<span className={css.header}>Cashsnab</span>*/}
    </div>

  )
}

export default Logo
